<template>
  <b-container class="margin-top">
    <div
      id="msg-result"
      style="display: none"
      class="success-msg"
      :class="{ 'error-msg': !password_changed }"
    >
      {{ result_msg }}
    </div>
    <b-form>
      <b-form-row>
        <h3>Reset your password</h3>
        <b-form-group
          id="user-new-password-group"
          label="New Password"
          label-for="user-old-password"
        >
          <b-input-group class="mb-2 mr-sm-2 mb-sm-0">
            <template v-slot:prepend>
              <b-input-group-text>
                <font-awesome-icon icon="user-secret"></font-awesome-icon>
              </b-input-group-text>
            </template>
            <b-form-input
              id="user-new-password"
              v-model="new_password_form.new_password"
              type="password"
              required
              placeholder="Enter New Password"
              size="lg"
              @input="checkPasswordMatch()"
            ></b-form-input>
          </b-input-group>
        </b-form-group>
      </b-form-row>
      <b-form-row>
        <b-form-group
          id="user-new-password-group"
          label="Confirm New Password"
          label-for="user-confirm-new-password"
        >
          <b-input-group class="mb-2 mr-sm-2 mb-sm-0">
            <template v-slot:prepend>
              <b-input-group-text>
                <font-awesome-icon icon="user-secret"></font-awesome-icon>
              </b-input-group-text>
            </template>
            <b-form-input
              id="user-confirm-new-password"
              v-model="new_password_form.confirmed_new_password"
              type="password"
              required
              placeholder="Confirm New Password"
              size="lg"
              @input="checkPasswordMatch()"
            ></b-form-input>
          </b-input-group>
        </b-form-group>
      </b-form-row>
      <b-form-row>
        <label
          v-if="!password_match && new_password_form.new_password"
          style="color: red"
          >Password doesn't match.</label
        >
        <label
          v-if="password_match && new_password_form.confirmed_new_password"
          style="color: green"
          >Password matches.</label
        >
      </b-form-row>
      <b-form-row>
        <b-button
          variant="primary"
          @click="changePassword()"
          :disabled="isButtonDisabled"
          >Change Password</b-button
        >
      </b-form-row>
      <br />
      <b-form-row>
        <b-link @click="redirectToLogin()">
          <div>
            <i class="fa fa-angle-left"></i> &nbsp; Go back to Login page...
          </div>
        </b-link>
      </b-form-row>
    </b-form>
  </b-container>
</template>

<script>
import axios from "axios";

export default {
  name: "reset-password-panel",
  title: "AptusCare Reset Password Panel",
  data: function () {
    return {
      new_password_form: {
        token: null,
        new_password: null,
        confirmed_new_password: null,
      },
      password_changed: true,
      result_msg: "Message",
      password_match: "",
      isButtonDisabled: true,
    };
  },
  methods: {
    redirectToLogin() {
      this.$router.replace({
        path: "/",
      });
    },
    async changePassword() {
      document.getElementById("msg-result").style.display = "none";
      var self = this;

      if (this.password_match) {
        var url_string = window.location.href;
        var splitted_string = url_string.split("/");
        this.new_password_form.token =
          splitted_string[splitted_string.length - 1];
        let response = await axios.post(
          "resetPassword",
          this.new_password_form
        );
        //console.log('Email response: ' , response);
        if (response.status == 200 && response.data.status === 1) {
          this.new_password_form.token = null;
          this.new_password_form.new_password = null;
          this.new_password_form.confirmed_new_password = null;
          this.password_changed = true;
          this.result_msg =
            response.data.name +
            ", your password has been changed, you will be redirect to the login page in 5 seconds";
          document.getElementById("msg-result").style.display = "block";
          setTimeout(function () {
            self.$router.replace({
              path: "/",
            });
          }, 5000);
        } else if (response.status == 200 && response.data.status === 2) {
          this.password_changed = false;
          this.result_msg = response.data.message;
          document.getElementById("msg-result").style.display = "block";
        } else if (response.status === 401 && response.data.status === 3) {
          this.password_changed = false;
          this.result_msg = response.data.message;
          document.getElementById("msg-result").style.display = "block";
        } else {
          //  output errors.
          this.password_changed = false;
          this.result_msg = response.data.message;
          document.getElementById("msg-result").style.display = "block";
        }
      } else {
        //password doesn't match
      }
    },
    checkPasswordMatch() {
      if (
        this.new_password_form.new_password ==
        this.new_password_form.confirmed_new_password
      ) {
        this.password_match = true;
        this.isButtonDisabled = false;
      } else {
        this.password_match = false;
        this.isButtonDisabled = true;
      }
    },
  },
};
</script>
<style scoped>
.verticle-centered {
  margin: auto;
  width: 50%;
  padding: 10px;
}
.margin-top {
  margin-top: 10%;
  padding: 5%;
  border: 1px solid #ccc;
}
.form-row {
  margin-left: 29%;
  margin-right: 29%;
}
.success-msg {
  background-color: aquamarine;
  color: #000;
  text-align: center;
}
.error-msg {
  background-color: crimson;
  color: #fff;
  text-align: center;
}
</style>