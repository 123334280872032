<template>
  <div class="verticle-center">
    <ResetPassword></ResetPassword>
  </div>
</template>

<script>
// @ is an alias to /src
import ResetPassword from "@/components/ResetPasswordComponent.vue";

export default {
  name: "reset-password-panel",
  components: {
    ResetPassword,
  },
};
</script>
